import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage01 from '../images/landing-image-01.png';
import HeroImage02 from '../images/landing-image-02.png';
import HeroImage03 from '../images/landing-image-03.png';
import FeatureRecap01 from '../images/feature-recap-01.svg';
import FeatureRecap02 from '../images/feature-recap-02.svg';
import FeatureRecap03 from '../images/feature-recap-03.svg';
import FeatureRecap04 from '../images/feature-recap-04.svg';
import Feature from '../images/feature.png';
import TryItArrow from '../images/image-arrow.svg';
import "../styles/global.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Instant Messaging Tool for Customer" />
    <div className="w3-row container">
      <div className="w3-col m4 l4 element">
        <h1>
          將 WhatsApp、Facebook Messenger 等的客戶訊息整合<br />促進銷售及開拓產品支援渠道
        </h1>
        <p>
          <strong>Grick</strong> 整合多個通訊應用的客戶訊息<br />幫助從業員向客戶銷售產品或解答查詢都可以化繁為簡
        </p>
      </div>
      <div className="w3-col m8 l8 element hero-image">
        <img src={HeroImage01} alt="" />
      </div>
    </div>

    <div className="w3-row container content-reverse">
      <div className="w3-col m8 l8 element hero-image">
        <img src={HeroImage02} alt="" />
      </div>
      <div className="w3-col m4 l4 element">
        <h1>
          群策群力<br />與隊友並肩作戰
        </h1>
        <p>
          客戶再刁鑽問題都可以與同事共同討論，共同思考組織答覆問題方法
        </p>
        <p>此外每個人可為產品建立常見問題集，讓團隊成員既迅速又精準答覆客戶提問</p>
      </div>
    </div>

    <div className="w3-row container">
      <div className="w3-col m4 l4 element">
        <h1>
          掌握客戶喜好<br />銷售過程事半功倍
        </h1>
        <p>
          完成對話後，客戶可對從業員提供意見及評價
        </p>
        <p>提升服務質素，提升公司形象之餘，你的潛在客戶因而會更喜歡你們的積極求變</p>
      </div>
      <div className="w3-col m8 l8 element hero-image">
        <img src={HeroImage03} alt="" />
      </div>
    </div>

    <div className="w3-row feature-recap">
      <div className="w3-col m12 l12">
        <h1>Grick 的好處點止得咁少</h1>
      </div>
      <div className="w3-col m6 l3 element recap">
        <img src={FeatureRecap01} alt="" />
        <h3>多合一的通訊方式</h3>
        <p>將 Facebook Messenger, WhatsApp 或 SMS 等客戶訊息整合於一個平台內作推銷或產品支援</p>
      </div>
      <div className="w3-col m6 l3 element recap">
        <img src={FeatureRecap02} alt="" />
        <h3>配置符合公司形象設計</h3>
        <p>設定聊天機器人與客戶保持聯繫之外，亦可為對話介面揀選的封面及顏色</p>
      </div>
      <div className="w3-col m6 l3 element recap">
        <img src={FeatureRecap03} alt="" />
        <h3>安裝於網站內<br />輕而易舉</h3>
        <p>由設定到使用只需幾個簡單步驟</p>
      </div>
      <div className="w3-col m6 l3 element recap">
        <img src={FeatureRecap04} alt="" />
        <h3>隨時隨地可與客戶<br />互動及交流</h3>
        <p>使用 iOS, Android 或 Web 等流動裝置與你的客戶溝通</p>
      </div>
    </div>

    {/* <div className="w3-row container">
      <div className="w3-col m12 l12 element">
        <h1 className="punchline">
          使用 Grick 達成更多的生意目標<br />獲得大家信賴及讚賞
        </h1>
        <div className="w3-row element customer-stories">
          <div className="w3-col l2 card story-01 quote">
            <div className="client-feedback">
              <img src={Feature} alt="" />
              <h4>提升客戶服務體驗</h4>
            </div>
          </div>
          <div className="w3-col l2 card story-02 quote">
            <div className="client-feedback">
              <img src={Feature} alt="" />
              <h4>增加 3 倍營業額</h4>
            </div>
          </div>
          <div className="w3-col l2 card story-03 quote">
            <div className="client-feedback">
              <img src={Feature} alt="" />
              <h4>掌握客戶喜好</h4>
            </div>
          </div>
          <div className="w3-col l2 card story-04 quote">
            <div className="client-feedback">
              <img src={Feature} alt="" />
              <h4>確立公司發展方向</h4>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="w3-row thinking-container thinking">
      <div className="w3-col m12 l12 element">
        <h1 className="punchline">你仲考慮緊?<br />向我們查詢或申請免費使用</h1>
        <p className="punchline">客戶服務團隊會為您選擇合適方案<br />對應您業務上的需要</p>
        <div className="try-it">
          <h3>撳呢個掣聯絡我哋</h3>
          <img src={TryItArrow} alt="" />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage